import { Controller } from "@hotwired/stimulus";
import * as bootstrap from "bootstrap"

export default class extends Controller {
  static targets = ['modal', 'player'];

  connect() {
    console.log("agents connected", this.targets.modal)
  }

  showModal(e) {
    e.preventDefault()
    this.bsModal = new bootstrap.Modal(this.modalTarget, {});
    if (this.isMobile()) {
      const aspectRatio = 380/680;
      const clientHeight = document.documentElement.clientHeight;
      const clientWidth = document.documentElement.clientWidth;
      const height = (clientWidth - 50) / aspectRatio
      console.log('Client height:', clientHeight);
      console.log('max height:', height);
      this.playerTarget.style.height = `${height}px`;
    }
    this.bsModal.show();
  }

  // onHide(event) {
  //   this.remove();
  // }

  disconnect() {

  }

  isMobile() {
    return /Mobi|Android/i.test(navigator.userAgent);
  }
}